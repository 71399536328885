<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-reward">
      <div>
      
      </div>
      <HistoryCoupon :detail="privilegeDetail" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import HistoryCoupon from "@/components/privilege/detail/HistoryCoupon";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    HistoryCoupon,
    Breadcrumb,
    OtherLoading
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "สิทธิพิเศษ",
          to: "/privilege?tabIndex=1"
        }
      ],
      breadcrumbListSet: [
        {
          text: "สิทธิพิเศษ",
          to: `/privilege/set/${this.$route.query.page}`
        }
      ],
      privilegeDetail: [],
      isLoading: true,
      id: this.$route.params.id
    };
  },
  async created() {
    await this.getCouponDetail();
  },
  methods: {
    async getCouponDetail() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/Privilege/coupon_detail/${this.id}`
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.privilegeDetail = data.detail.detail;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  //   margin-top: 80px;
  margin-bottom: 100px;
  //   min-height: 100vh;
}
</style>
